import React from 'react';
import './AboutChapterTimeline.scss';
import TimelineItem from '../TimelineItem/TimelineItem';

const timelinedata = [
  {
    date: 'Early March 2020',
    title: 'Founders Assemble',
    description: 'Jean Choi, Ryan Tsai, Vincent Chen, and Justin Tran began making plans to establish a chapter of Theta Tau at Cal Poly SLO and initiated correspondence with other founding members at chapters in the Southwest region amidst the COVID-19 pandemic.'
  },
  {
    date: 'End of March 2020',
    title: 'Founding Class',
    description: 'Jean, Ryan, Vincent, and Justin recruited 17 other Cal Poly engineers to create the founding class of 21 students.'
  },
  {
    date: 'March 2020 - October 2020',
    title: 'Working Towards Colony',
    description: 'The founding class laid the groundwork for establishing a colony of Theta Tau, and held events to grow in service, profession, and brotherhood.'
  },
  {
    date: 'Late October 2020',
    title: 'Colony Petition',
    description: 'After months of preparation, the petition for colony recognition was finished and submitted to National for review.'
  },
  {
    date: 'November 7, 2020',
    title: 'Colony Status',
    description: 'After receiving a positive response from National, an initiation ceremony was held via Zoom, and Theta Tau SLO became an officially recognized colony.'
  },
  {
    date: 'Mid November 2020 - Present',
    title: 'Working towards Chapter',
    description: 'Theta Tau SLO begins work to solidify and develop as a colony, while working towards the goal of attaining full chapter status.'
  },
]

function AboutChapterTimeline() {
    return (
      <div className="timeline-container">
        <div className="timeline-title">
          Chapter History
        </div>
        <div className="timeline-content">
          {timelinedata.map(entry => (
            <TimelineItem date={entry.date} title={entry.title} description={entry.description}/>
          ))}
        </div>
      </div>
    );
}

export default AboutChapterTimeline;
