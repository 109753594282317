import React from 'react';
import './AboutPage.scss';
import homeimg from '../../images/BackgroundImages/main.jpg';
import Navigation from '../Navigation/Navigation';
import AboutStatistics from '../AboutStatistics/AboutStatistics';
import AboutChapterTimeline from '../AboutChapterTimeline/AboutChapterTimeline';
import AboutNationalHistory from '../AboutNationalHistory/AboutNationalHistory';
import { Parallax } from 'react-parallax';

//const { Link } = require("react-router-dom");

function AboutPage() {
const parallaxTextStyleLarge = {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    fontSize: "96px",
    };
    // const parallaxTextStyleSmall = {
    // color: "white",
    // position: "absolute",
    // top: "60%",
    // left: "50%",
    // transform: "translate(-50%,-50%)",
    // fontSize: "36px",
    // };

    return (
    <div>
        <Navigation showOnLoad={true} />
        <Parallax bgImage={homeimg} strength={500}>
          <div style={{ height: '700px' }} className='dimBG'>
            <div style={parallaxTextStyleLarge} >About Us</div>
            {/* <div style={parallaxTextStyleSmall}>Learn more about Theta Tau</div> */}
        </div>
        </Parallax>
        {/* <div className="AboutSection">
          <AboutGallery />
        </div> */}
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>The 3 Pillars of Theta Tau</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="AboutSection">
              <AboutPillars />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Colony Statistics</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="AboutSection">
              <AboutStatistics />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Colony History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="AboutSection">
              <AboutChapterTimeline />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>National Theta Tau History</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="AboutSection">
              <AboutNationalHistory />
            </div>
          </AccordionDetails>
        </Accordion> */}
        <div className="AboutSection">
          <AboutStatistics />
        </div>
        <div className="AboutSection">
          <AboutChapterTimeline />
        </div>
        <div className="AboutSection">
          <AboutNationalHistory />
        </div>
    </div>
    );
}

export default AboutPage;
