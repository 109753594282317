import React from 'react';
import './Footer.scss'; 
import InstagramIcon from '@mui/icons-material/Instagram';

const { Link } = require("react-router-dom");

function Footer() {
  return (
      <div className="FooterContainer">
        <div className="FooterContents">
          <div className="MiddleContainer">
            <div className="MiddleLarge">Contact Us</div>
            <div className="MiddleSmall">thetatauslo@gmail.com</div>
            <div className="MiddleLarge">Follow Us</div>
            <div className="MiddleIcons">
              {/*<a className="FooterLink" href="https://www.facebook.com/Theta-Tau-SLO-101680238406054/" target="_blank" rel="noopener">
                <FacebookIcon />
              </a> */}
              <a className="FooterLink" href="https://www.instagram.com/thetatauslo/" target="_blank" rel="noreferrer">
                <InstagramIcon style={{margin: '0 0 0 10px'}} />
              </a>
            </div>
            <div className="MiddleLarge">National Theta Tau</div>
            <a className="MiddleSmall FooterLink" href="https://thetatau.org" target="_blank" rel="noreferrer">
              thetatau.org
            </a>
          </div>
          <div className="FooterNav">
            <div className="FooterBld">Navigation</div>
            <Link className="FooterNavItem FooterLink" to="/">Home</Link>
            <Link className="FooterNavItem FooterLink" to="/about">About</Link>
            <Link className="FooterNavItem FooterLink" to="/rush">Recruitment</Link>
            <Link className="FooterNavItem FooterLink" to="/brothers">Members</Link>
            <Link className="FooterNavItem FooterLink" to="/profession">Profession</Link>
            <Link className="FooterNavItem FooterLink" to="/brotherhood">Kinship</Link>
            <Link className="FooterNavItem FooterLink" to="/service">Service</Link>

          </div>
        </div>
      </div>
  )
};

export default Footer
