import React from 'react';
import './AlumniPage.scss';
import AlumCard from '../BrotherCard/AlumCard'
// import SortCards from '../SortCards/SortCards';
import Navigation from '../Navigation/Navigation'
import { Parallax } from 'react-parallax';
import homeimg from '../../images/BackgroundImages/main.jpg';

function BrothersPage(props) {
  let brothers = [ ...props.brothers ];
  const FoundingClass = brothers.filter(brother => brother.class === 'FoundingA').sort((a, b) => a.name.split(" ")[1] < b.name.split(" ")[1] ? -1 : 1);
  const AlphaClass = brothers.filter(brother => brother.class === 'AlphaA').sort((a, b) => a.name.split(" ")[1] < b.name.split(" ")[1] ? -1 : 1);
//   const betaClass = brothers.filter(brother => brother.class == 'Beta').sort((a, b) => a.name.split(" ")[1] < b.name.split(" ")[1] ? -1 : 1);
//   const gammaClass = brothers.filter(brother => brother.class == 'Gamma').sort((a, b) => a.name.split(" ")[1] < b.name.split(" ")[1] ? -1 : 1);
//   const deltaClass = brothers.filter(brother => brother.class == 'Delta').sort((a, b) => a.name.split(" ")[1] < b.name.split(" ")[1] ? -1 : 1);
//   //const epsilonClass = brothers.filter(brother => brother.class == 'Epsilon').sort((a, b) => a.name.split(" ")[1] < b.name.split(" ")[1] ? -1 : 1);

    //let sortfilters = []

    const parallaxTextStyleLarge = {
      color: "white",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      fontSize: "96px",
    };
    const parallaxTextStyleSmall = {
      color: "white",
      position: "absolute",
      top: "64%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      fontSize: "36px",
    };

    return (
      <div className="BrothersPage">
        <Navigation showOnLoad={true} />
        <div className="top-image">
          <Parallax bgImage={homeimg} strength={500}>
            <div className="top-heading" style={{height: '560px'}}>
              <div style={parallaxTextStyleLarge}>Alumni</div>
              <div style={parallaxTextStyleSmall}>Meet the Theta Tau Alumni</div>
            </div>
          </Parallax>
        </div>
        <div className="BrothersPage"></div>
          {/* <div className="SortCardsContainer">
            <div>Sort</div>
            <div>
              <SortCards />
            </div>
          </div> */}
          <div className="BrothersCardsContainer">
          <h1>Founding Class Alumni</h1>
            <div className="BrothersCards">
              {FoundingClass.map((brother) => {
                  return <AlumCard
                    name={brother.name}
                    major={brother.major[0]}
                    key={brother.id}
                    headshot={brother.headshot} 
                    history={props.history} />
                })}
            </div>
            <h1>Alpha Class Alumni</h1>
            <div className="BrothersCards">
              {AlphaClass.map((brother) => {
                  return <AlumCard
                    name={brother.name}
                    major={brother.major[0]}
                    key={brother.id}
                    headshot={brother.headshot} 
                    history={props.history} />
                })}
            </div>
           {/* <h1>Gamma Class</h1>
            <div className="BrothersCards">
              {gammaClass.map((brother) => {
                  return <BrotherCard
                    name={brother.name}
                    year={brother.year}
                    major={brother.major[1]}
                    key={brother.id}
                    headshot={brother.headshot} 
                    history={props.history} />
                })}
            </div>
            <h1>Delta Class</h1>
              <div className="BrothersCards">
                {deltaClass.map((brother) => {
                  return <BrotherCard
                    name={brother.name}
                    year={brother.year}
                    major={brother.major[1]}
                    key={brother.id}
                    headshot={brother.headshot}
                    history={props.history} />
                  })}
              </div> */}
            {/* <h1>Epsilon Class</h1>
              <div className="BrothersCards">
                {epsilonClass.map((brother) => {
                  return <BrotherCard
                    name={brother.name}
                    year={brother.year}
                    major={brother.major[1]}
                    key={brother.id}
                    headshot={brother.headshot}
                    history={props.history} />
                  })}
              </div> */}
          </div>
      </div>
    );
  }
  
  export default BrothersPage;