import './App.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NewHomePage from './components/NewHomePage/NewHomePage';
import KinshipPage from './components/BrotherhoodPage/BrotherhoodPage';
import Profile from './components/Profile/Profile';
import Footer from './components/Footer/Footer';
import AboutPage from './components/AboutPage/AboutPage';
import ProfessionPage from './components/ProfessionPage/ProfessionPage';
import MembersPage from './components/MembersPage/MembersPage';
import ServicePage from './components/ServicePage/ServicePage';
import RushPage from './components/RushPage/RushPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AlumniPage from './components/AlumniPage/AlumniPage';
import { brothers } from './BrothersObjects';
import { Alum } from './BrothersObjects';

// for(const property in brothers[0])
//   console.log(brothers[0][property])

const profileRoutes = brothers.map((brother) => <Route exact path={"/Kinship/" + brother.name.toLowerCase().replace(" ", "")} render={(props) => <Profile {...props} brother={brother} />} key={brother.id} />);

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div>
        <Switch>
          {/*<Route exact path="/" component={HomePage} />*/}
          <Route exact path="/" component={NewHomePage} />
          <Route exact path="/Members" render={(props) => <MembersPage {...props} brothers={brothers} />} />
          {profileRoutes}
          <Route exact path="/Alumni" render={(props) => <AlumniPage {...props} brothers={Alum} />} />
          {profileRoutes}
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/profession" component={ProfessionPage} />
          <Route exact path="/Kinship" component={KinshipPage} />
          <Route exact path="/service" component={ServicePage} />
          <Route exact path="/rush" component={RushPage} />
          <Route path="/" render={() => <div>404</div>} />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;