import React from 'react';
import './AboutNationalHistory.scss';
import logo from '../../images/IconsLogos/ttcoatarms.png';

//const { Link } = require("react-router-dom");

function AboutNationalHistory() {
    return (
      <div className="nathistory-container">
        <div className="nathistory-title">
            National Theta Tau History
        </div>
        <div className="nathistory-content">
            {/*<img className="nathistory-img" src={logo} alt="logo"/>*/}
            <div className="nathistory-par">
            On October 15, 1904, Erich J. Schrader, Elwin L. Vinyl, William M. Lewis, and Isaac B. Hanks founded “The Society of Hammer and Tongs”
            at the University of Minnesota in Minneapolis, Minnesota. It wasn’t until the first National convention was held at the University of
            Minnesota in 1911 that the Greek letters “Theta Tau” were formally adopted. Today, with over 40,000 members initiated, Theta Tau has
            become one of the largest and foremost professional engineering fraternities. Currently, Theta Tau has established branches on over 100
            college campuses, representing men and women across all engineering disciplines and backgrounds.
            </div>
        </div>
      </div>
    );
}

export default AboutNationalHistory;
