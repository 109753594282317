import React, { useState } from 'react';
import './Navigation.scss';
import { Navbar, Nav } from 'react-bootstrap';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
//import logo from '../../images/IconsLogos/logoredsmall.png';

//const { Link } = require("react-router-dom");

function Navigation({ showPosY, showOnLoad }) {
  const [hideNavOnScroll, setHideNavOnScroll] = useState(showOnLoad);

  useScrollPosition(({ prevPos, currPos }) => {
    let isThere;
    if (showPosY) {
      isThere = currPos.y > prevPos.y && currPos.y < -(showPosY)
    }
    else {
      isThere = currPos.y > prevPos.y
    }
    if (isThere !== hideNavOnScroll) setHideNavOnScroll(isThere)
  }, [hideNavOnScroll])

  return (
    // <Navbar className={hideNavOnScroll ? "nav-f5f5f5 navbar" : "nav-f5f5f5 navbar hide" } expand="sm" variant="light" fixed="top">
    <Navbar className="nav-f5f5f5 navbar" expand="sm" variant="light" fixed="top">
      <Navbar.Brand href="/">
        Theta Tau | SLO
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end" >
        <div className='nav'>
        <Nav>
          {/*<Nav.Link bsPrefix="nav-link-custom" href="/">Home</Nav.Link>*/}
          <Nav.Link bsPrefix="nav-link-custom" href="/">Home</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/about">About Us</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/rush">Recruitment</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/Members">Members</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/Alumni">Alumni</Nav.Link>
          {/* <Nav.Link bsPrefix="nav-link-custom" href="/service">Alumni</Nav.Link> */}
          <Nav.Link bsPrefix="nav-link-custom" href="/profession">Profession</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/Kinship">Kinship</Nav.Link>
          <Nav.Link bsPrefix="nav-link-custom" href="/service">Service</Nav.Link>
          
          {/* dropdown? */}
        </Nav>
        </div>
        {/* <Link to={"/rush"}>
          <button className="RushButton">RUSH</button>
        </Link> */}
      </Navbar.Collapse>
    </Navbar>
    );
  }
{/*}
  <NavDropdown title="Dropdown" id="basic-nav-dropdown">
  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
  <NavDropdown.Divider />
  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
  </NavDropdown> 
*/}
  
  export default Navigation;
