import React from 'react';
import './BrotherCard.scss';

const { Link } = require("react-router-dom");

function BrotherCard(props) {
    return (
        <div className="BrotherCard">
            <img 
                className="BrotherImg" src={props.headshot} alt={props.name} 
                onClick={() => {
                    props.history.push("/brothers/" + props.name.toLowerCase().replace(" ", ""))
                }} 
            />
            <Link className="BrotherName" to={"/brothers/" + props.name.toLowerCase().replace(" ", "")}>
                <div className="BrotherName">{props.name}</div>
            </Link>
            <Link className="BrotherName" to={"/brothers/" + props.name.toLowerCase().replace(" ", "")}>
                <div className="BrotherYearMajor">{props.major}, Class of {props.year}</div>
            </Link>
        </div>
    )
};

export default BrotherCard
